/* Mixins */
.config-image-web-component .page-body-margin .color_card .color_card_theme {
  margin-bottom: 40px;
  margin-top: 20px;
  padding-left: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.config-image-web-component .page-body-margin .color_card .color_card_primary {
  padding-left: 70px;
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.color_card_list {
  cursor: pointer;
  display: inline-block;
  width: 100px;
  height: 30px;
  border-radius: 5px;
  text-align: center;
  line-height: 30px;
  margin-right: 10px;
  position: relative;
}
.color_card_list .color_card_item_icon {
  position: absolute;
  right: 5px;
  bottom: 5px;
}